import React, { useCallback, useMemo, useState } from 'react'
import backgroundWhite from './img/white-decorate.svg'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Event as GAEvent } from '../../components/GA'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { size } from '../../constants'
import { useSelector } from 'react-redux'

const TextContent = styled(({ className }) => {
  const [copied, setCopied] = useState(false)
  return (
    <div className={className}>
      <Title.H2asH1styles>
        Вот бы мне
        <br /> подарили
        <br /> сертификат...
      </Title.H2asH1styles>
      <Text.Large>
        Если вы сами будете рады такому подарку, просто
        <br /> отправьте ссылку на эту страницу друзьям или
        <br /> поделитесь ей в соцсетях.
      </Text.Large>
      <Button.NewOutline
        transparent
        onClick={useCallback(() => {
          navigator.clipboard.writeText(
            `${process.env.GATSBY_SITEURL}/sertifikat-na-psihoterapiyu/`
          )
          setCopied(true)
          GAEvent.CopyCertificateLink()
        }, [])}
      >
        {copied ? 'Ссылка скопирована' : 'Скопировать ссылку'}
      </Button.NewOutline>
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  position: relative;

  & > ${Title.H2asH1styles} {
    margin-bottom: 24px;

    & br:nth-child(1) {
      display: none;
    }

    @media (max-width: ${size.lg}) {
      & br:nth-child(1),
      & br:nth-child(2) {
        display: block;
      }
    }

    @media (max-width: ${size.md}) {
      margin-bottom: 16px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 8px;

      & br:nth-child(1) {
        display: none;
      }
    }
  }

  & > ${Text.Large} {
    margin-bottom: 32px;

    @media (max-width: ${size.md}) {
      margin-bottom: 24px;
      max-width: 418px;

      & br {
        display: none;
      }
    }

    @media (max-width: ${size.sm}) {
      max-width: unset;

      & br {
        display: none;
      }
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 8px;
    }
  }

  ${Button.NewOutline} {
    width: fit-content;

    @media (max-width: ${size.sm}) {
      width: 100%;
    }

    @media (max-width: ${size.xs}) {
      height: 56px;
    }
  }
`

const CertificateImg = styled(GatsbyImage)``

export const Content = styled(({ className, image }) => (
  <Grid className={className}>
    <TextContent />
    <CertificateImg
      alt="подарочный сертификат"
      image={image}
      objectFit="contain"
    />
  </Grid>
))`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 124px;
  z-index: 3;

  @media (max-width: ${size.md}) {
    gap: 80px;
  }

  @media (max-width: ${size.sm}) {
    gap: 48px;
  }

  @media (max-width: ${size.xs}) {
    gap: 61px;
  }

  & ${CertificateImg} {
    position: absolute;
    top: -181px;
    right: -120px;
    max-width: 976px;
    max-height: 732px;

    @media (max-width: ${size.lg}) {
      top: -76px;
      right: -208px;
      max-width: 877px;
      max-height: 657px;
    }

    @media (max-width: ${size.md}) {
      right: -170px;
      top: -70px;
      max-width: 714px;
      max-height: 535px;
    }

    @media (max-width: ${size.sm}) {
      display: none;
    }
  }
`

export const WouldGiveMeCertificate = styled(({ className, images }) => {
  const windowWidth = useSelector(selectDeviceSize)
  const image = useMemo(
    () =>
      withArtDirection(getImage(images.certificateImageLg), [
        windowWidth > 1023
          ? {
              media: '(max-width: 1439px)',
              image: getImage(images.certificateImageMd)
            }
          : {
              media: '(max-width: 1024px)',
              image: getImage(images.certificateImageSm)
            }
      ]),
    [windowWidth]
  )

  return (
    <section className={className}>
      <Content image={image} />
    </section>
  )
})`
  width: 100%;
  position: relative;
  margin: 0 auto;
  background-color: ${color.background};
  background: linear-gradient(
    180deg,
    #ffffff 21.9%,
    rgba(255, 255, 255, 0) 106.58%
  );
  padding-bottom: 124px;

  &::after {
    content: '';
    background-color: ${color.background};
    height: 220px;
    position: absolute;
    width: inherit;
    left: 0;
    top: -220px;
  }

  &::before {
    content: '';
    background: url(${backgroundWhite}) no-repeat;
    background-position: 50% 50%;
    background-size: 100%;
    height: 72px;
    position: absolute;
    width: 100%;
    left: 0;
    top: -292px;
  }

  @media (max-width: ${size.lg}) {
    &::before {
      background-size: cover;
      height: 56px;
      top: -276px;
    }
  }

  @media (max-width: ${size.md}) {
    padding-bottom: 80px;

    &::before {
      height: 48px;
      top: -268px;
    }
  }

  @media (max-width: ${size.sm}) {
    padding-bottom: 48px;

    &::after {
      height: 170px;
      top: -170px;
    }
    &::before {
      height: 32px;
      top: -202px;
    }
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 61px;
  }
`
