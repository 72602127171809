import React from 'react'
import styled from 'styled-components'
import { Grid } from '../atoms/Grid'
import { QuestionsList } from './QuestionsList'
import { Title } from '../atoms/Title'

const QuestionList = styled(({ className }) => (
  <div className={className}>
    <Title.H2asH1styles>Вопросы</Title.H2asH1styles>
    <QuestionsList
      onlyOnOpenSendGA
      className={className}
      eventGA="getCertificateAnswer"
      page="Certificate"
    />
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > li > button {
    background-color: transparent;
  }
`

export const QuestionListCertificate = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <QuestionList />
    </Grid>
  </section>
))`
  background: '#E6F0FD';
`
