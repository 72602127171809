import React from 'react'
import fireworks from './img/fireworks.svg'
import styled from 'styled-components'
import {
  CertificateCard,
  ForeignCertificateCard,
  ImportantBlock
} from '../../molecules/CertificateCard'
import { Grid } from '../../atoms/Grid'
import { Link } from '../../atoms/GatsbyLink'
import { TariffPlate } from '../../molecules/Plates/TariffPlate'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { WorldwideCertificate } from '../../molecules/Plates/WorldwideCertificate'
import { size } from '../../constants'

export const certificates = {
  individual: [
    {
      count: 2,
      price: '5 000 ₽',
      description:
        'Дают возможность сформулировать запрос, наметить план действий и получить поддержку.'
    },
    {
      count: 4,
      price: '10 000 ₽',
      description:
        'Помогают сделать первые шаги навстречу себе и почувствовать себя лучше.'
    },
    {
      count: 8,
      price: '20 000 ₽',
      description:
        'Позволяют проработать небольшие проблемы и продвинуться в решении сложных запросов.'
    }
  ],
  family: [
    {
      count: 2,
      price: '7 000 ₽',
      description:
        'Дают сделать первые шаги навстречу друг другу и помогают увидеть близкого другими глазами.'
    },
    {
      count: 4,
      price: '14 000 ₽',
      description:
        'Помогают перестроить коммуникацию, выработать новые способы общаться в непростых ситуациях.'
    },
    {
      count: 8,
      price: '28 000 ₽',
      description:
        'Дают проработать основные проблемы, перестроить деструктивные паттерны взаимодействия.'
    }
  ]
}

export const CertificateCards = styled(({ className }) => (
  <div className={className}>
    <ForeignCertificateCard nominal="2500" />
    {certificates.individual.map(({ count, price, description }, index) => (
      <CertificateCard
        key={count}
        description={description}
        frameAmount={index + 1}
        nominal={price.replace(/[^0-9]/g, '')}
        price={price}
        sessionAmount={count}
        type="individual"
      />
    ))}
  </div>
))`
  min-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;

  @media (max-width: ${size.md}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`

const Section = styled.section`
  position: relative;
  width: 100%;

  @media (max-width: ${size.sm}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${size.xs}) {
    display: flex;
    align-items: center;
  }
`
export const GiftCertificates = styled(({ className }) => (
  <Section>
    <Grid className={className}>
      <Title.H2asH1styles>Подарочные сертификаты</Title.H2asH1styles>
      <Text.Large>
        Можно купить удобные пакеты баллов на сессии с психологом.
      </Text.Large>
      <CertificateCards />
      <WorldwideCertificate />
      <Link to="/blog/kak-dolgo-hodit-k-psihology/">
        Как долго нужно ходить к психологу?
      </Link>
      <ImportantBlock />
    </Grid>
  </Section>
))`
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    background-image: url(${fireworks});
    background-size: cover;
    width: 892px;
    height: 242px;
    top: -81px;
    right: -59px;
    z-index: 0;

    @media (max-width: ${size.lg}) {
      top: -98px;
      right: -316px;
    }

    @media (max-width: ${size.md}) {
      width: 692px;
      height: 187px;
      top: -58px;
      right: -354px;
    }

    @media (max-width: ${size.sm}) {
      display: none;
      max-width: 90%;
    }
  }

  & > ${Title.H2asH1styles} {
    z-index: 3;
    margin-bottom: 16px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 8px;
    }
  }

  & > ${Text.Large} {
    z-index: 3;
    margin-bottom: 32px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 24px;
    }
  }

  & ${TariffPlate} {
    z-index: 3;
    margin-bottom: 24px;
  }

  & ${CertificateCards} {
    z-index: 3;
    margin-bottom: 40px;
  }

  & ${ImportantBlock} {
    z-index: 5;
  }

  & > ${Link} {
    margin: 0 auto;
    margin-bottom: 64px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: ${size.md}) {
      margin-bottom: 48px;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 40px;
    }
  }
`
