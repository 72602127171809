import React, { useCallback } from 'react'
import blueDecoration from './img/blue-decoration.svg'
import giftImage from './img/gift_new.svg'
import styled from 'styled-components'
import { ReactComponent as ArrowStrokeSVG } from './img/arrow-stroke.svg'
import { Button } from '../../atoms/Button'
import { Event as GAEvent } from '../../components/GA'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { gifteryWidgetId } from '../../pages/sertifikat-na-psihoterapiyu'
import { size } from '../../constants'

const GiftsImage = styled(({ className }) => (
  <img
    alt="Изображение подарков, перевязанных ленточкой"
    className={className}
    src={giftImage}
  />
))`
  pointer-events: none;
`

const PageHeader = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <Title.H1>Подарочный сертификат на консультацию к психологу</Title.H1>
      <Text.Large>
        Помогите близкому человеку
        <br /> разобраться в сложной ситуации,
        <br /> лучше понять себя и стать
        <br /> счастливее.
      </Text.Large>
      <Button.NewPrimary
        data-giftery-widget={gifteryWidgetId}
        data-giftery-widget-color={color.button.giftery}
        href={`https://widget.giftery.cards/?productId=${gifteryWidgetId}`}
        onClick={useCallback(() => {
          GAEvent.OpenCertificateForm('firstScreen')
        }, [])}
        target="_blank"
        type="externalLink"
      >
        Купить сертификат
      </Button.NewPrimary>
      <GiftsImage />
    </Grid>
  </div>
))`
  ${Grid} {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @media (max-width: ${size.sm}) {
      & br {
        display: none;
      }
    }

    & > ${Title.H1} {
      margin-bottom: 24px;
      z-index: 3;

      @media (max-width: ${size.md}) {
        margin-bottom: 16px;
      }

      @media (max-width: ${size.xs}) {
        margin-bottom: 8px;
      }
    }

    & > ${Text.Large} {
      margin-bottom: 32px;
      z-index: 3;

      @media (max-width: ${size.md}) {
        margin-bottom: 24px;
      }

      @media (max-width: ${size.xs}) {
        margin-bottom: 16px;
      }
    }

    & > ${Button.NewPrimary} {
      z-index: 3;
      @media (max-width: ${size.sm}) {
        width: 100%;
      }
    }

    & ${GiftsImage} {
      position: absolute;
      right: -214px;
      bottom: -156px;

      @media (max-width: ${size.lg}) {
        height: 429px;
        right: -200px;
        bottom: -132px;
      }

      @media (max-width: ${size.md}) {
        height: 309px;
        right: -181px;
        bottom: -100px;
      }

      @media (max-width: ${size.sm}) {
        height: 287px;
        right: -50px;
        bottom: -212px;
        width: 100%;
      }

      @media (max-width: ${size.xs}) {
        bottom: -232px;
        right: -50px;
      }

      @media (max-width: ${size.xs375}) {
        bottom: -232px;
        right: -30px;
      }
    }
  }
`

const StepNum = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border: 2px solid #16c8bb;
  border-radius: 50%;
  z-index: 3;
`

const StepNumWithArrow = styled(({ className, number }) => (
  <div className={className}>
    <StepNum>
      <Title.H3>{number}</Title.H3>
    </StepNum>
    {number !== 3 && <ArrowStrokeSVG />}
  </div>
))`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.sm}) {
    & > svg {
      display: none;
    }
  }
`

const Step = styled(({ className, title, number, Description }) => (
  <div className={className}>
    <StepNumWithArrow number={number} />
    <Title.H3>{title}</Title.H3>
    <Description />
  </div>
))`
  display: flex;
  flex-direction: column;
  z-index: 3;

  ${StepNumWithArrow} {
    margin-bottom: 24px;
  }

  & > ${Title.H3} {
    margin-bottom: 16px;
  }

  & ${Title.H3}, ${Text.Medium} {
    color: ${color.text.white};
  }
`

export const howItSteps = [
  {
    number: 1,
    title: 'Выберите подарок',
    description:
      'Продаём сертификаты на баллы, которые можно потратить на индивидуальные и семейные сессии',
    Description: styled(({ className }) => (
      <Text.Medium className={className}>
        Продаём сертификаты на баллы, которые можно потратить на индивидуальные
        и семейные сессии
      </Text.Medium>
    ))`
      padding-right: 48px;

      @media (max-width: ${size.md}) {
        padding-right: 0;
      }
    `
  },
  {
    number: 2,
    title: 'Оплатите покупку',
    description:
      'Банковской картой или через систему быстрых платежей на сайте',
    Description: styled(({ className }) => (
      <Text.Medium className={className}>
        Банковской картой или через систему быстрых платежей на сайте
      </Text.Medium>
    ))`
      @media (max-width: ${size.lg}) {
        padding-right: 24px;
      }

      @media (max-width: ${size.md}) {
        padding-right: 0;
      }
    `
  },
  {
    number: 3,
    title: 'Получите сертификат',
    description:
      'Моментально отправим сертификат на вашу почту или напрямую человеку, которому вы его дарите',
    Description: styled(({ className }) => (
      <Text.Medium className={className}>
        Моментально отправим сертификат на вашу почту или напрямую человеку,
        которому вы его дарите
      </Text.Medium>
    ))``
  }
]

const HowItSteps = styled(({ className }) => (
  <div className={className}>
    {howItSteps.map((step) => (
      <Step {...step} key={step.title} />
    ))}
  </div>
))`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;
  padding-bottom: 56px;
  padding-top: 72px;

  @media (max-width: ${size.lg}) {
    grid-template-columns: repeat(3, 304px);
    gap: 24px;
    padding-bottom: 40px;
    padding-top: 56px;
  }

  @media (max-width: ${size.md}) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding-bottom: 32px;
    padding-top: 48px;
  }

  @media (max-width: ${size.sm}) {
    height: auto;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 40px;
  }
`

const HowIt = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <HowItSteps />
    </Grid>
  </div>
))`
  z-index: 1;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    background: url(${blueDecoration}) no-repeat;
    background-position: 50% 50%;
    background-size: 104%;
    height: 72px;
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 3;

    @media (max-width: ${size.lg}) {
      height: 56px;
    }

    @media (max-width: ${size.md}) {
      height: 48px;
    }

    @media (max-width: ${size.sm}) {
      background-size: cover;
      height: 32px;
    }
  }

  &::after {
    content: '';
    background-color: #2963a3;
    height: calc(100% - 68px);
    position: absolute;
    width: inherit;
    z-index: 2;
    left: 0;
    bottom: 0;

    @media (max-width: ${size.lg}) {
      height: calc(100% - 50px);
    }

    @media (max-width: ${size.md}) {
      height: calc(100% - 42px);
    }

    @media (max-width: ${size.sm}) {
      height: calc(100% - 26px);
    }
  }
`

export const GiveCare = styled(({ className, images }) => (
  <section className={className}>
    <PageHeader images={images} />
    <HowIt />
  </section>
))`
  display: flex;
  flex-direction: column;
  gap: 44px;
  width: 100%;
  background-color: ${color.background};
  position: relative;
  overflow: hidden;

  ${Grid} {
    width: 100%;
  }

  @media (max-width: ${size.lg}) {
    gap: 34px;
  }

  @media (max-width: ${size.md}) {
    gap: 27px;
  }

  @media (max-width: ${size.sm}) {
    gap: 144px;
  }
`
