import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Event as GAEvent } from '../../components/GA'
import { ReactComponent as GlobeSvg } from './img/globe.svg'
import { Text } from '../../atoms/Text'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

export const WorldwideCertificate = styled(({ className }) => (
  <div className={className}>
    <Content />
    <Button.Grey
      AfterContent={<GlobeSvg />}
      href={`https://giftup.app/place-order/9ee7b9ca-6152-4bcc-8bef-08daa15859c7?platform=hosted`}
      onClick={useCallback(() => {
        GAEvent.OpenopenGiftup('openGiftup')
      }, [])}
      target="_blank"
      type="externalLink"
    >
      Купить
    </Button.Grey>
  </div>
))`
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid #dedede;
  padding: 12px 16px 16px 16px;
  background-color: ${color.background};
  border-radius: 16px;
  margin-bottom: 24px !important;
  width: 588px;
  height: 120px;
  margin: 0 auto;

  @media (max-width: ${size.lg}) {
    width: 100%;
    padding: 32px;
    width: 75%;
  }

  @media (max-width: ${size.md}) {
    padding: 16px 16px 16px 16px;
    width: 100%;
    height: 100px;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    height: 184px;
    margin-top: -24px;
    width: 100%;

    & > ${Button.Grey} {
      width: 100%;
    }
  }

  @media (max-width: ${size.xs}) {
    height: 220px;
  }

  @media (max-width: ${size.xs}) {
    margin-bottom: 8px;
  }
  }
`

const Content = styled(({ className }) => (
  <div className={className}>
    <Text.Large>Забота по всему миру</Text.Large>
    <Text.Large>
      Сертификат можно купить с карты, выданной зарубежным банком. Перейти в
      сервис GiftUp и оплатите сертификат через PayPal.
    </Text.Large>
  </div>
))`
display: flex;
flex-direction: column;
justify-content: start:

@media (max-width: ${size.lg}) {
  width: 75%;
}

@media (max-width: ${size.md}) {
  width: 80%;
  padding-right: 20px;

}

@media (max-width: ${size.sm}) {
  width: 100%;
  padding-right: 0;
  padding-bottom: 16px;
}

& > ${Text.Large} {
  text-align:  start;
  font-size: 14px;
  line-height: 20px;
}

& > ${Text.Large}:nth-child(1) {
  color: #011632;
  font-weight: 700;
  font-size: 18px;
  line-heit: 26px;
  margin-bottom: 2px;
}
`
