import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../../atoms/GatsbyLink'
import { Text } from '../../atoms/Text'
import { ReactComponent as Wallet } from '../../../static/img/tariff/wallet.svg'
import { size } from '../../constants'

const WalletIcon = styled(Wallet)`
  display: block;
  overflow: visible;
`

export const TariffPlate = styled(({ className }) => (
  <div className={className}>
    <WalletIcon />
    <Text.Average>
      Для оплаты сессий доступна оплата в евро. Все тарифы в евро можно
      посмотреть{' '}
      <ExternalLink
        href="https://promo.youtalk.ru/prices-in-euro"
        target="_blank"
      >
        здесь
      </ExternalLink>
    </Text.Average>
  </div>
))`
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: ${({ fill }) => fill && '#fefaec'};
  padding: ${({ fill }) => (fill ? '16px' : '0')};
  border-radius: 16px;
  z-index: 7;

  & ${Text.Average} {
    display: inline-block;
    margin: auto 0;
    font-weight: 400;
    white-space: ${({ fill }) => fill && 'nowrap'};

    @media (max-width: ${size.md}) {
      white-space: normal;
    }
  }

  @media (max-width: ${size.md}) {
    padding: ${({ fill }) => (fill ? '6px 16px' : '0')};
  }

  @media (max-width: ${size.sm}) {
    padding: ${({ fill }) => (fill ? '8px 16px' : '0')};
  }
`
