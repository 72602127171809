import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import SEO, { getSeoMedia } from '../atoms/SEO'
import styled from 'styled-components'
import { BreadCrumbs, BreadcrumbSEO } from '../molecules/BreadCrumbs'
import { FAQJsonLd, JsonLd } from 'gatsby-plugin-next-seo'
import { GiftCertificates } from '../organisms/CertificateCards'
import { GiveCare, howItSteps } from '../organisms/GiveCare'
import { QuestionListCertificate } from '../molecules/QuestionListCertificate'
import { WouldGiveMeCertificate } from '../organisms/WouldGiveMeCertificate'
import { WriteUs } from '../organisms/WriteUs'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'ramda'
import { questionListCertificate } from '../molecules/QuestionsList/questionListCertificate'
import { size } from '../constants'

const jsonLdQuestionListData = questionListCertificate.map(({ ask, ans }) => ({
  question: ask,
  answer: ReactDOMServer.renderToStaticMarkup(ans())
}))

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: calc(var(--vh, 1vh) * 100);
  background: #e6f0fd;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.main`
  background: #e6f0fd;

  & ${GiveCare} {
    padding-top: 64px;
    margin-bottom: 72px;

    @media (max-width: ${size.lg}) {
      padding-top: 72px;
      margin-bottom: 56px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 40px;
      margin-bottom: 48px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 32px;
      margin-bottom: 40px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
    }
  }

  & ${GiftCertificates} {
    margin-bottom: 141px;

    @media (max-width: ${size.lg}) {
      margin-bottom: 111px;
    }

    @media (max-width: ${size.md}) {
      margin-bottom: 98px;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 48px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 40px;
    }
  }

  & ${QuestionListCertificate} {
    margin-bottom: 76px;

    @media (max-width: ${size.lg}) {
      margin-bottom: 68px;
    }

    @media (max-width: ${size.md}) {
      margin-bottom: 60px;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 48px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 40px;
    }
  }
`

export const gifteryWidgetId = process.env.GATSBY_GIFTERY_WIDGET_ID || 18831

const CertificateNew = ({ data }) => (
  <App>
    <SEO
      children={
        <>
          <BreadcrumbSEO />
          <JsonLd
            json={{
              '@context': 'https://schema.org',
              '@type': 'HowTo',
              name: 'Как приобрести подарочный сертификат',
              step: howItSteps.map(({ title, description }, index) => ({
                '@type': 'HowToSection',
                name: title,
                position: String(index + 1),
                itemListElement: [
                  {
                    '@type': 'HowToStep',
                    position: String(index + 1),
                    name: title,
                    url: `${process.env.GATSBY_SITEURL}/sertifikat-na-psihoterapiyu/`,
                    itemListElement: [
                      {
                        '@type': 'HowToDirection',
                        position: String(index + 1),
                        text: description
                      }
                    ]
                  }
                ]
              }))
            }}
          />
          <FAQJsonLd questions={jsonLdQuestionListData} />
        </>
      }
      description="Подарочный сертификат на онлайн-консультации с психологом —  отличный подарок для женщин и мужчин ➨Позаботьтесь о своих близких с  YouTalk!"
      images={getSeoMedia([
        { pathname: '/img/og/certificate.png', alt: 'Certificate' }
      ])}
      title="Купить подарочный сертификат к психологу — психотерапия для женщин и мужчин в подарок | YouTalk"
    />
    <Page>
      <Header />
      <BreadCrumbs fill={color.background} />
      <Main>
        <GiveCare
          images={pick(['giftsCertificateLg', 'giftsCertificate'], data)}
        />
        <GiftCertificates />
        <WouldGiveMeCertificate
          images={pick(
            ['certificateImageMd', 'certificateImageLg', 'certificateImageSm'],
            data
          )}
        />
        <QuestionListCertificate />
        <WriteUs />
      </Main>
      <Footer />
    </Page>
  </App>
)

export const query = graphql`
  query {
    certificateImageLg: file(
      relativePath: { eq: "img/certificate/sert-lg.webp" }
    ) {
      childImageSharp {
        gatsbyImageData
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    certificateImageMd: file(
      relativePath: { eq: "img/certificate/sert-md.webp" }
    ) {
      childImageSharp {
        gatsbyImageData
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    certificateImageSm: file(
      relativePath: { eq: "img/certificate/sert-sm.webp" }
    ) {
      childImageSharp {
        gatsbyImageData
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default CertificateNew
