import MessengerButton from '../../molecules/MessengerButton'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import whiteDecoration from './img/white-decoration.svg'
import { Event as GAEvent } from '../../components/GA'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { messengersUrl } from '../../../static/db.json'
import { size } from '../../constants'

const ButtonGroup = styled(({ className }) => (
  <div className={className}>
    <MessengerButton.Telegramm
      href="https://t.me/YouTalk_clients_bot"
      onClick={useCallback(() => {
        GAEvent.GetCertificateHelp('telegram')
      }, [])}
      text="Написать"
    />
    <MessengerButton.Whatsapp
      href={messengersUrl.whatsapp}
      onClick={useCallback(() => {
        GAEvent.GetCertificateHelp('whatsapp')
      }, [])}
      text="Написать"
    />
  </div>
))`
  display: flex;
  flex-direction: row;
  gap: 32px;

  & > a {
    height: 48px !important;

    @media (max-width: ${size.xs}) {
      height: 56px !important;
    }
  }

  @media (max-width: ${size.md}) {
    gap: 24px;
  }

  @media (max-width: ${size.sm}) {
    gap: 16px;
  }

  @media (max-width: ${size.xs}) {
    flex-direction: column;
  }
`

const Content = styled(({ className }) => (
  <div className={className}>
    <Title.H2asH1styles>
      Остались
      <br /> вопросы?
    </Title.H2asH1styles>
    <Text.Large>
      Специалисты по подбору психолога помогут найти ответ. Мы работаем с 10:00
      до 21:00 по Москве.
    </Text.Large>
    <ButtonGroup />
  </div>
))`
  max-width: 519px;

  & > ${Title.H2asH1styles} {
    margin-bottom: 24px;

    @media (max-width: ${size.md}) {
      margin-bottom: 16px;
    }

    & > br {
      display: none;

      @media (max-width: ${size.lg}) {
        display: block;
      }

      @media (max-width: ${size.sm}) {
        display: none;
      }
    }
  }

  & > ${Text.Large} {
    margin-bottom: 32px;

    @media (max-width: ${size.lg}) {
      max-width: 438px;
    }

    @media (max-width: ${size.md}) {
      margin-bottom: 24px;
      max-width: 333px;
    }
  }
`

const WriteUsImg = styled.img``
const GreenBlob = styled.img`
  pointer-events: none;
`

export const WriteUs = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <Content />
      <WriteUsImg
        alt="Изображение телефона с открытым диалогом с администратором YouTalk"
        src="/img/decor-hand.png"
      />
    </Grid>
    <GreenBlob alt="Зеленый полукруг" src="/img/icons/blob.svg" />
  </section>
))`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background: transparent;
  position: relative;
  padding: 182px 0 188px;

  @media (max-width: ${size.lg}) {
    padding: 48px 0 131px;
  }

  @media (max-width: ${size.md}) {
    padding: 40px 0 127px;
  }

  @media (max-width: ${size.sm}) {
    padding: 48px 0 36px;
  }

  @media (max-width: ${size.xs}) {
    padding: 48px 0 24px;
  }

  &::after {
    content: '';
    display: block;
    background: linear-gradient(0deg, #ffffff, #ffffff),
      linear-gradient(180deg, rgba(255, 255, 255, 0) 48%, #ffffff 80.41%),
      #eff5fb;
    height: calc(100% - 108px);
    position: absolute;
    width: inherit;
    left: 0;
    bottom: 0;
    z-index: 0;

    @media (max-width: ${size.lg}) {
      background-size: cover;
      height: calc(100% - 48px);
    }

    @media (max-width: ${size.md}) {
      height: calc(100% - 40px);
    }

    @media (max-width: ${size.sm}) {
      height: calc(100% - 32px);
    }
  }

  &::before {
    content: '';
    background: url(${whiteDecoration}) no-repeat;
    background-position: 50% 50%;
    background-size: 104%;
    height: 108px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;

    @media (max-width: ${size.lg}) {
      height: 48px;
      background-position: center;
    }

    @media (max-width: ${size.md}) {
      height: 40px;
    }

    @media (max-width: ${size.sm}) {
      height: 48px;
    }
  }

  & ${WriteUsImg} {
    position: absolute;
    right: 108px;
    top: -147px;
    width: 469px;

    @media (max-width: ${size.lg}) {
      width: 400px;
      right: 66px;
      top: -19px;
    }

    @media (max-width: ${size.md}) {
      width: 365px;
      right: 6px;
      top: 28px;
    }

    @media (max-width: ${size.sm}) {
      display: none;
    }
  }

  & ${GreenBlob} {
    position: absolute;
    z-index: 3;
    top: 64px;
    right: 0;
    pointer-events: none;
    width: 184px;
    height: auto;

    @media (max-width: ${size.lg}) {
      top: 40px;
      right: -100px;
    }

    @media (max-width: ${size.md}) {
      top: 32px;
      right: -80px;
      width: 136px;
    }

    @media (max-width: ${size.sm}) {
      display: none;
    }
  }

  & ${Grid} {
    position: relative;
    z-index: 3;
  }
`
